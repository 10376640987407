// customize dark components background in popover containers(like Modal, Drawer, Card, Popover, Popconfirm, Notification, ...)
// for dark theme
.popover-customize-bg(@containerClass, @background: @popover-background, @prefix: @ant-prefix)
  when
  (@theme = dark) {
  @picker-prefix-cls: ~'@{prefix}-picker';
  @slider-prefix-cls: ~'@{prefix}-slider';
  @anchor-prefix-cls: ~'@{prefix}-anchor';
  @collapse-prefix-cls: ~'@{prefix}-collapse';
  @tab-prefix-cls: ~'@{prefix}-tabs';
  @timeline-prefix-cls: ~'@{prefix}-timeline';
  @tree-prefix-cls: ~'@{prefix}-tree';
  @card-prefix-cls: ~'@{prefix}-card';
  @badge-prefix-cls: ~'@{prefix}-badge';
  @transfer-prefix-cls: ~'@{prefix}-transfer';
  @calendar-prefix-cls: ~'@{prefix}-picker-calendar';
  @calendar-picker-prefix-cls: ~'@{prefix}-picker';
  @table-prefix-cls: ~'@{prefix}-table';

  @popover-border: @border-width-base @border-style-base @popover-customize-border-color;

  .@{containerClass} {
    .@{picker-prefix-cls}-clear,
    .@{slider-prefix-cls}-handle,
    .@{anchor-prefix-cls}-wrapper,
    .@{collapse-prefix-cls}-content,
    .@{timeline-prefix-cls}-item-head,
    .@{card-prefix-cls} {
      background-color: @background;
    }

    .@{transfer-prefix-cls} {
      &-list {
        &-header {
          background: @background;
          border-bottom: @popover-border;
        }
        &-content-item:not(.@{transfer-prefix-cls}-list-content-item-disabled):hover {
          background-color: @item-hover-bg;
        }
      }
    }

    tr.@{table-prefix-cls}-expanded-row {
      &,
      &:hover {
        > td {
          background: #272727;
        }
      }
    }
    .@{table-prefix-cls}.@{table-prefix-cls}-small {
      thead {
        > tr {
          > th {
            background-color: @background;
            border-bottom: @popover-border;
          }
        }
      }
    }
    .@{table-prefix-cls} {
      background-color: @background;
      .@{table-prefix-cls}-row-expand-icon {
        border: @popover-border;
      }

      tfoot {
        > tr {
          > th,
          > td {
            border-bottom: @popover-border;
          }
        }
      }

      thead {
        > tr {
          > th {
            background-color: #272727;
            border-bottom: @popover-border;
          }
        }
      }

      tbody {
        > tr {
          > td {
            border-bottom: @popover-border;
            &.@{table-prefix-cls}-cell-fix-left,
            &.@{table-prefix-cls}-cell-fix-right {
              background-color: @background;
            }
          }
          &.@{table-prefix-cls}-row:hover {
            > td {
              background: @table-header-sort-active-bg;
            }
          }
        }
      }
      &.@{table-prefix-cls}-bordered {
        .@{table-prefix-cls}-title {
          border: @popover-border;
        }

        // ============================= Cell =============================
        thead > tr > th,
        tbody > tr > td,
        tfoot > tr > th,
        tfoot > tr > td {
          border-right: @popover-border;
        }

        // Fixed right should provides additional border
        .@{table-prefix-cls}-cell-fix-right-first::after {
          border-right: @popover-border;
        }

        // ============================ Header ============================
        table > {
          thead {
            > tr:not(:last-child) > th {
              border-bottom: @border-width-base @border-style-base @border-color-split;
            }
          }
        }

        // =========================== Content ============================
        .@{table-prefix-cls}-container {
          border: @popover-border;
        }

        // ========================== Expandable ==========================
        .@{table-prefix-cls}-expanded-row-fixed {
          &::after {
            border-right: @popover-border;
          }
        }

        .@{table-prefix-cls}-footer {
          border: @popover-border;
        }
      }
      .@{table-prefix-cls}-filter-trigger-container-open {
        background-color: #525252;
      }
    }

    .@{calendar-prefix-cls}-full {
      background-color: @background;
      .@{calendar-picker-prefix-cls}-panel {
        background-color: @background;
        .@{calendar-prefix-cls}-date {
          border-top: 2px solid @popover-customize-border-color;
        }
      }
    }

    .@{tab-prefix-cls} {
      &.@{tab-prefix-cls}-card .@{tab-prefix-cls}-card-bar .@{tab-prefix-cls}-tab-active {
        background-color: @background;
        border-bottom: @border-width-base solid @background;
      }
    }

    .@{badge-prefix-cls} {
      &-count {
        box-shadow: 0 0 0 1px @background;
      }
    }

    .@{tree-prefix-cls} {
      &-show-line {
        .@{tree-prefix-cls}-switcher {
          background: @background;
        }
      }
    }
  }
}
